<template>
    <div class="card card-height">
        <div class="table-responsive billing-table">
            <table class="table custom-table-padding table-width">
                <thead>
                    <tr>
                        <th class=" pl-10 particular">Particulars</th>
                        <th class="qty">Qty</th>
                        <th class="rate">Unit Price</th>
                        <th class="sub-amount">Amount</th>
                        <th class="discount">Discount(%)</th>
                        <th class="d-amount">Discount Amount</th>
                        <th class="after-amount">Amount after Discount</th>
                        <th class="vat">VAT(%)</th>
                        <th class="vat-amount">VAT Amount</th>
                        <th class="total">Total Amount</th>
                        <th class="th-action">Action</th>
                    </tr>
                </thead>
                <tbody ref="tableRef">
                    <RowInline 
                        v-for="(item, i) in tableItems" :key="item.id"
                        :item="item" :index="i" :vats="vats"
                        @onOpenEditItem="onOpenEditItem"
                        @onDeleteItem="onDeleteItem"
                        :type="type"
                    />

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                   
                </tbody>
            </table>
        </div>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>

    <div class="row add-new-line">
        <div class="col-12 mb-2" v-if="type === 'pharmacy'">
            <div>
                <button @click="$emit('addNewItem')" class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-plus"></i>
                    Add new
                </button>
            </div>
        </div>

        <div class="col-md-5">
            <MoneyReceiptForm :grand-total="parseFloat(grandTotal)" :isHospitalInvoice="true" ref="moneyReceiptForm" />

            <div class="mt-3">
                <label class="form-label" for="description">Ledger Memo</label>
                <vField
                    as="textarea"
                    name="description"
                    type="number"
                    class="form-control"
                    v-model="formData.description"
                />
            </div>
        </div>

        <div class="col-md-5 ml-auto">
            <div>	
                <table class="table create-billing border table-totals">
                    <tbody>
                        <tr>
                            <td class="product">Sub-Total Amount</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right">৳ {{ getSubTotal.toFixed(4) }}</td>
                        </tr>
                        <tr>
                            <td class="product">Line Discount</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right">৳ {{ getDiscountAmount.toFixed(4) }}</td>
                        </tr>
                        <tr>
                            <td class="product">VAT</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right">৳ {{ getVatAmount.toFixed(4) }}</td>
                        </tr>
                        <tr>
                            <td class="product">Total Bill Amount</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right">৳ {{ getTotal.toFixed(4) }}</td>
                        </tr>
                        <tr>
                            <td class="product final-discount">
                                <span>Discount / Round up Adj</span>
                                <div class="price-adj justify-content-end">
                                    <span class="mr-5">%</span>
                                    <input
                                        v-model.trim="discountAdjPer"
                                        type="number"
                                        class="form-control form-control-sm percent-adj"
                                        placeholder="Adj. Percent"
                                        :min="0"
                                        :max="100"
                                        @input="updateAdjustmentAmount"
                                    >
                                </div>
                            </td>
                            <td class="comma-sep">:</td>
                            <td class="price">
                                <div class="price-adj justify-content-end">
                                    <span class="mr-5">৳</span>
                                    <input
                                        v-model.trim="formData.adjustment_amount"
                                        type="number"
                                        class="form-control form-control-sm discount-adj"
                                        placeholder="Adj. Amount"
                                        :min="0"
                                        @input="setAdjAmount"
                                    >
                                </div>
                            </td>
                        </tr>
                        <tr v-if="formData.adjustment_amount > 0">
                            <td class="product px-2">Select discount head</td>
                            <td class="comma-sep">:</td>
                            <td style="font-weight: normal">
                                <v-select
                                    placeholder="Select Discount Head"
                                    v-model="formData.discount_head_id"
                                    :options="discountAccountHeads"
                                    label="name"
                                    :reduce="name => name.id"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="product">Net Bill Amount</td>
                            <td class="comma-sep">:</td>
                            <td class="price d-flex align-items-center justify-content-end">
                                <span class="mr-5">৳</span>
                                <input
                                    v-model.trim="grandTotal"
                                    type="number"
                                    class="form-control form-control-sm discount-adj"
                                    placeholder="Grand Total"
                                    :min="0"
                                    @input="updateGrandTotal"
                                    @blur="validateGrandTotal"
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-md-12 text-center">
            <div class="add-new-line mb-2">
                <button
                    type="button"
                    class="btn btn-primary btn-lg btn-block"
                    @click="$emit('handleSubmit')"
                    :disabled="submitLoading"
                >
                    <i class="far fa-credit-card"></i>
                    <span class="ms-1">Save & Print</span>
                </button>
            </div>
        </div>

    </div>
</template>

<script setup>    
    import RowInline from '@/components/molecule/company/hospital/investigation/RowInline.vue';
    import useDetectOutsideClick from '@/services/useDetectOutsideClick';
    import {computed, inject, reactive, ref, watch} from 'vue';
    import MoneyReceiptForm from "@/components/molecule/company/hospital/billing/MoneyReceiptForm.vue";
    
    const moneyReceiptForm = ref(null)
    const tableRef = ref(null)
    const discountAdjPer = ref(null);
    const grandTotal = ref(0);
    const emit = defineEmits(['addNewItem', 'handleSubmit']);
    const formData = inject('formData');

    const props = defineProps({
        tableItems: {
            type: Array,
        },
        discountAccountHeads: {
            type: Array,
        },
        submitLoading: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'pharmacy'
        }
    })

    const vats = reactive([
        {
            value: 10,
            name: '10%'
        },
        {
            value: 15,
            name: '15%'
        },
        {
            value: 20,
            name: '20%'
        },
    ]);

    const toFixed = (number, decimal = 4) => {
        return parseFloat(number.toFixed(decimal));
    }

    const getSubTotal = computed(() => {
        return props.tableItems.map(item => item.sub_total )
        .reduce((acc, currentValue) => acc + currentValue, 0);
    })

    const getDiscountAmount = computed(() => {
        return props.tableItems.map(item => item.discount_amount )
        .reduce((acc, currentValue) => acc + currentValue, 0);
    })

    const getVatAmount = computed(() => {
        return props.tableItems.map(item => item.vat_amount )
        .reduce((acc, currentValue) => acc + currentValue, 0);
    })

    const getTotal = computed(() => {
        return props.tableItems.map(item => item.total )
        .reduce((acc, currentValue) => acc + currentValue, 0);
    })

    const setGrandTotal = () => {
        grandTotal.value = (getTotal.value - formData.value.adjustment_amount ?? 0);
    }

    const tableData = computed(() => props.tableItems)
    
    const onOpenEditItem = (index) => {
        resetItemList(index);
    }

    const onDeleteItem = (index) => {
        props.tableItems.splice(index, 1);
    }

    const resetItemList = (selectedIndex = -1) => {
        for(let i = 0; i < props.tableItems.length; i++){
            
            if(selectedIndex === i){
                props.tableItems[i].is_edit = true;
                continue;
            }
            props.tableItems[i].is_edit = false;
        }
    }

    const updateAdjustmentAmount = () => {
        let discountPercent = parseFloat(discountAdjPer.value);

        if (discountPercent > 100) {
            discountAdjPer.value = 100;
            discountPercent = 100;
        }

        formData.value.adjustment_amount = null;
        const discountAmount = (getTotal.value / 100) * discountPercent;

        if(discountPercent > 0) {
            formData.value.adjustment_amount = toFixed(discountAmount);
            setGrandTotal();
            return;
        }

        formData.value.adjustment_amount = null;
        setGrandTotal();
    }

    const setAdjAmount = () => {
        const adjAmount = parseFloat(formData.value.adjustment_amount);
        const subTotal = parseFloat(getTotal.value);

        if (adjAmount <= 0) {
            formData.value.adjustment_amount = null;
            setGrandTotal();
            return;
        }

        if (adjAmount > subTotal) {
            formData.value.adjustment_amount = toFixed(subTotal);
            setGrandTotal();
            return;
        }

        formData.value.adjustment_amount = isNaN(adjAmount) ? null : toFixed(adjAmount);
        setGrandTotal();
    }

    const updateGrandTotal = () => {
        const total = parseFloat(grandTotal.value);
        const subTotal = parseFloat(getTotal.value);

        if (!total || total < 0) {
            formData.value.adjustment_amount = null;
        }

        if (total > subTotal) {
            grandTotal.value = subTotal;
            formData.value.adjustment_amount = null;
            return;
        }

        formData.value.adjustment_amount = toFixed(subTotal - total);
    }

    const validateGrandTotal = () => {
        const total = parseFloat(grandTotal.value);
        const subTotal = parseFloat(getTotal.value);

        if (!total) {
            grandTotal.value = subTotal;
        }
    }

    watch(getTotal, () => setGrandTotal());

    watch(tableData, () => {
        if(tableData.value.length < 1){
            moneyReceiptForm.value.generateMoneyReceiptNumber()
            discountAdjPer.value = 0;
            formData.value.adjustment_amount = 0;
        }
    })

    useDetectOutsideClick(tableRef, () => {
        resetItemList();
    })
</script>

<style scoped>
.billing-table {
    overflow-x: initial;
}

.final-discount{
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-content: space-between;
}
.percent-adj{
    width: 50%;
    display: inherit !important;
}
.price-adj{
    display: flex;
    align-items: center;
}

.discount-adj{
    width: 50%;
}

.create-billing > :not(first-child) > * {
    padding-left: 10px !important;
}

.table-totals{
    font-weight: bold;
}
.product{
    width: 60%;
}
.comma-sep{
    text-align: right;
}

.create-billing > :not(caption) > * > * {
    padding: 0.5rem 0.2rem !important;
}
.particular{
    width: 25%;
}
.qty{
    width: 6%;
}
.discount{
    width: 5%;
}


.card-height{
    min-height: 200px
}

.add-new-line {
    margin-top: 4%;
}
</style>